import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { ErrorOutlineOutlined } from "@mui/icons-material";

import _ from "lodash";

export default function ManualScoreEntry(props) {
   const [value, setValue] = useState(props.value);

   const handleChange = (e) => {
      let value = parseInt(e.target.value);
      if (isNaN(value)) {
         value = 0;
      }
      setValue(value);
   };

   const handleKeyPress = (e) => {
      if (e.keyCode == 13) {
         handleSubmit();
         e.target.blur();
      }
   };

   const handleSubmit = () => {
      props.callback(props.arrayKey, props.objKey, props.row, props.col, value);
   };

   return (
      <TextField
         placeholder='Score'
         value={value}
         onChange={handleChange}
         onKeyDown={handleKeyPress}
         onBlur={handleSubmit}
      />
   );
}
