import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { SocketContext, socket } from "./context/socket";

import Home from "./pages/Home";

import Viewer from "./viewer/Viewer";
import ViewerWheel from "./viewer/ViewerSpintheWheel";
import ViewerTugofWar from "./viewer/ViewerTugofWar";
import LeaderboardViewer from "./viewer/ViewerLeaderboard";
import TierlistViewer from "./viewer/ViewerTierlist";

import StyleEditor from "./pages/StyleEditor";
import StyleEditorTugofWar from "./pages/StyleEditorTugofWar";
import StyleEditorLeaderboard from "./pages/StyleEditorLeaderboard";
import StyleEditorSpintheWheel from "./pages/StyleEditorSpintheWheel";

import Test from "./pages/Test";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./index.css";
import "./leaderboard.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
      <SocketContext.Provider value={socket}>
         <Router>
            <Routes>
               <Route path='/editor' element={<StyleEditor />} />
               <Route
                  path='/editor/tugofwar'
                  element={<StyleEditorTugofWar />}
               />
               <Route
                  path='/editor/spinthewheel'
                  element={<StyleEditorSpintheWheel />}
               />
               <Route
                  path='/editor/leaderboard'
                  element={<StyleEditorLeaderboard />}
               />

               <Route path='/viewer' element={<Viewer />} />
               <Route path='/viewer/:pollType' element={<Viewer />} />
               <Route path='/viewertugofwar' element={<ViewerTugofWar />} />
               <Route path='/viewerwheel' element={<ViewerWheel />} />
               <Route
                  path='/viewerleaderboard'
                  element={<LeaderboardViewer />}
               />
               <Route path='/viewertierlist' element={<TierlistViewer />} />

               <Route path='/test' element={<Test />} />
               <Route path='/' element={<Home />} />
            </Routes>
         </Router>
      </SocketContext.Provider>
   </React.StrictMode>
);
