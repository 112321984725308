import React, { useState, useEffect, useRef } from "react";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// COMPONENTS
import Polls from "./Polls";
import Wheel from "./Wheel";
import Leaderboard from "./Leaderboard";
import Tierlist from "./Tierlist";

// TABS
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
         style={{
            height: props.height,
            overflow: "hidden",
         }}
      >
         {value === index && (
            <Box sx={{ py: 3, position: "relative" }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}

// THEME
const darkTheme = createTheme({
   palette: {
      mode: "dark",
      navBkgnd: {
         dark: "#000",
      },
      col1: {
         dark: "#222",
      },
      col2: {
         dark: "#333",
      },
      col3: {
         dark: "#444",
      },
      col4: {
         dark: "#555",
      },
      background: {
         default: "#151515",
      },
      boxOverride: {
         border: { dark: "rgba(255,255,255,.25)" },
         background: {
            dark: "rgba(0,0,0,.35)",
         },
      },
   },
   components: {
      MuiAccordion: {
         styleOverrides: {
            root: {
               "&.Mui-expanded": {},
            },
         },
      },
   },
});

// ENV VARIABLES
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Home() {
   const previewWidth = useRef();
   const headerHeight = useRef();

   const thumbScale =
      previewWidth.current &&
      (parseInt(window.getComputedStyle(previewWidth.current).width) - 20) /
         1920;

   const [value, setValue] = useState(0);
   const [showPreview, setShowPreview] = useState(false);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   const togglePreviews = () => {
      setShowPreview(!showPreview);
   };

   useEffect(() => {
      setDimensions({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   useEffect(() => {
      setTimeout(function () {
         window.dispatchEvent(new Event("resize"));
      }, 10);
   }, [showPreview]);

   // RESIZE LISTENER
   const [dimensions, setDimensions] = useState({
      height: window.innerHeight,
      width: window.innerWidth,
   });

   useEffect(() => {
      function handleResize() {
         setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);
      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   return (
      <ThemeProvider theme={darkTheme}>
         <CssBaseline />
         <Box sx={{ p: 2 }}>
            <Grid
               container
               justifyContent='center'
               alignItems='stretch'
               columnSpacing={2}
            >
               <Grid item xs={10} ref={headerHeight}>
                  <Typography variant='h6' component='div'>
                     DASHBOARD
                  </Typography>
               </Grid>

               <Grid item xs={2} sx={{ mb: 1.5, textAlign: "right" }}>
                  <Button
                     onClick={togglePreviews}
                     variant='outlined'
                     color='warning'
                  >
                     TOGGLE PREVIEWS
                  </Button>
               </Grid>

               <Grid item xs={showPreview ? 10 : 12}>
                  <Box
                     sx={{
                        typography: "body1",
                     }}
                  >
                     <Box
                        sx={{
                           borderBottom: 1,
                           borderColor: "divider",
                           height: 60,
                        }}
                     >
                        <Tabs
                           value={value}
                           onChange={handleChange}
                           aria-label='Tabs'
                        >
                           <Tab label='Polling' {...a11yProps(0)} />
                           <Tab label='Spin the Wheel' {...a11yProps(1)} />
                           <Tab label='Leaderboard' {...a11yProps(2)} />
                           <Tab label='Tierlist' {...a11yProps(3)} />
                           <Tab label='vMix' {...a11yProps(4)} />
                        </Tabs>
                     </Box>
                     <TabPanel
                        value={value}
                        height={
                           headerHeight.current &&
                           window.innerHeight -
                              100 -
                              parseInt(
                                 window.getComputedStyle(headerHeight.current)
                                    .height
                              )
                        }
                        index={0}
                     >
                        <Polls
                           height={
                              headerHeight.current &&
                              window.innerHeight -
                                 100 -
                                 parseInt(
                                    window.getComputedStyle(
                                       headerHeight.current
                                    ).height
                                 )
                           }
                        />
                     </TabPanel>
                     <TabPanel value={value} index={1}>
                        <Wheel
                           height={
                              headerHeight.current &&
                              window.innerHeight -
                                 100 -
                                 parseInt(
                                    window.getComputedStyle(
                                       headerHeight.current
                                    ).height
                                 )
                           }
                        />
                     </TabPanel>
                     <TabPanel value={value} index={2}>
                        <Leaderboard
                           height={
                              headerHeight.current &&
                              window.innerHeight -
                                 100 -
                                 parseInt(
                                    window.getComputedStyle(
                                       headerHeight.current
                                    ).height
                                 )
                           }
                        />
                     </TabPanel>
                     <TabPanel value={value} index={3}>
                        <Tierlist
                           height={
                              headerHeight.current &&
                              window.innerHeight -
                                 100 -
                                 parseInt(
                                    window.getComputedStyle(
                                       headerHeight.current
                                    ).height
                                 )
                           }
                        />
                     </TabPanel>
                     <TabPanel value={value} index={4}>
                        <Typography
                           variant='h6'
                           component='div'
                           sx={{ mt: 3, mb: 1 }}
                        >
                           Viewer URLs
                        </Typography>
                        <Button
                           variant='outlined'
                           href={SERVER_URL + "/viewer"}
                        >
                           Polling Viewer
                        </Button>
                        <br />
                        {SERVER_URL + "/viewer"}
                        <br />
                        <br />
                        <Button
                           variant='outlined'
                           href={SERVER_URL + "/viewertugofwar"}
                        >
                           Tug of War Viewer
                        </Button>
                        <br />
                        {SERVER_URL + "/viewertugofwar"}
                        <br />
                        <br />
                        <Button
                           variant='outlined'
                           href={SERVER_URL + "/viewerwheel"}
                        >
                           Spin the Wheel Viewer
                        </Button>
                        <br />
                        {SERVER_URL + "/viewerwheel"}
                        <br />
                        <br />
                        <Button
                           variant='outlined'
                           href={SERVER_URL + "/viewerleaderboard"}
                        >
                           Leaderboard Viewer
                        </Button>
                        <br />
                        {SERVER_URL + "/viewerleaderboard"}
                        <br />
                        <br />
                        <Button
                           variant='outlined'
                           href={SERVER_URL + "/viewertierlist"}
                        >
                           Tierlist Viewer
                        </Button>
                        <br />
                        {SERVER_URL + "/viewertierlist"}
                        <br />
                        <br />
                        <Typography
                           variant='h6'
                           component='div'
                           sx={{ mt: 3, mb: 1 }}
                        >
                           API Triggers
                        </Typography>
                        <b>Spin the Wheel</b>
                        <br />
                        {SERVER_URL + "/api/trigger/wheel/spin"}
                        <br />
                        <br />
                        <b>Reset the Wheel</b>
                        <br />
                        {SERVER_URL + "/api/trigger/wheel/reset"}
                     </TabPanel>
                  </Box>
               </Grid>
               {showPreview && (
                  <Grid item xs={2}>
                     <Stack
                        spacing={4}
                        ref={previewWidth}
                        sx={{
                           pt: 5,
                           minHeight: "100%",
                           backgroundColor: "#000",
                           overflow: "hidden",
                        }}
                     >
                        <Box>
                           <Typography
                              variant='h6'
                              component='div'
                              sx={{
                                 textAlign: "center",
                                 fontSize: 14,
                              }}
                           >
                              POLLING VIEWER
                           </Typography>
                           <Box
                              sx={{
                                 width: "100%",
                                 height: 1080 * thumbScale,
                                 position: "relative",
                              }}
                           >
                              <iframe
                                 title='PollViewer'
                                 scrolling='no'
                                 src='/viewer'
                                 style={{
                                    width: 1920,
                                    height: 1080,
                                    transformOrigin: "top left",
                                    transform:
                                       "translateX(10px) scale(" +
                                       thumbScale +
                                       ")",
                                 }}
                                 className='bgTransparency'
                              />
                           </Box>
                        </Box>
                        <Box>
                           <Typography
                              variant='h6'
                              component='div'
                              sx={{
                                 textAlign: "center",
                                 fontSize: 14,
                              }}
                           >
                              TUG OF WAR VIEWER
                           </Typography>
                           <Box
                              sx={{
                                 width: "100%",
                                 height: 1080 * thumbScale,
                                 position: "relative",
                              }}
                           >
                              <iframe
                                 title='TugofWarViewer'
                                 scrolling='no'
                                 src='/viewertugofwar'
                                 style={{
                                    width: 1920,
                                    height: 1080,
                                    transformOrigin: "top left",
                                    transform:
                                       "translateX(10px) scale(" +
                                       thumbScale +
                                       ")",
                                 }}
                                 className='bgTransparency'
                              />
                           </Box>
                        </Box>
                        <Box>
                           <Typography
                              variant='h6'
                              component='div'
                              sx={{
                                 textAlign: "center",
                                 fontSize: 14,
                              }}
                           >
                              SPIN THE WHEEL VIEWER
                           </Typography>
                           <Box
                              sx={{
                                 width: "100%",
                                 height: 1080 * thumbScale,
                                 position: "relative",
                              }}
                           >
                              <iframe
                                 title='SpintheWheelViewer'
                                 scrolling='no'
                                 src='/viewerwheel'
                                 style={{
                                    width: 1920,
                                    height: 1080,
                                    transformOrigin: "top left",
                                    transform:
                                       "translateX(10px) scale(" +
                                       thumbScale +
                                       ")",
                                 }}
                                 className='bgTransparency'
                              />
                           </Box>
                        </Box>
                        <Box>
                           <Typography
                              variant='h6'
                              component='div'
                              sx={{
                                 textAlign: "center",
                                 fontSize: 14,
                              }}
                           >
                              LEADERBOARD VIEWER
                           </Typography>
                           <Box
                              sx={{
                                 width: "100%",
                                 height: 1080 * thumbScale,
                                 position: "relative",
                              }}
                           >
                              <iframe
                                 title='LeaderboardViewer'
                                 scrolling='no'
                                 src='/viewerleaderboard'
                                 style={{
                                    width: 1920,
                                    height: 1080,
                                    transformOrigin: "top left",
                                    transform:
                                       "translateX(10px) scale(" +
                                       thumbScale +
                                       ")",
                                 }}
                                 className='bgTransparency'
                              />
                           </Box>
                        </Box>
                        <Box>
                           <Typography
                              variant='h6'
                              component='div'
                              sx={{
                                 textAlign: "center",
                                 fontSize: 14,
                              }}
                           >
                              TIERLIST VIEWER
                           </Typography>
                           <Box
                              sx={{
                                 width: "100%",
                                 height: 1080 * thumbScale,
                                 position: "relative",
                              }}
                           >
                              <iframe
                                 title='TierlistViewer'
                                 scrolling='no'
                                 src='/viewertierlist'
                                 style={{
                                    width: 1920,
                                    height: 1080,
                                    transformOrigin: "top left",
                                    transform:
                                       "translateX(10px) scale(" +
                                       thumbScale +
                                       ")",
                                 }}
                                 className='bgTransparency'
                              />
                           </Box>
                        </Box>
                     </Stack>
                  </Grid>
               )}
            </Grid>
         </Box>
      </ThemeProvider>
   );
}

export default Home;
