import React, { useState, useContext, useEffect } from "react";

import { SocketContext } from "../context/socket";
import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import ColorPicker from "../editor/ColorPicker.js";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";

import RankingComponent from "../components/RankingComponent";
import ManualScoreEntry from "../components/ManualScoreEntry";

import StyleMenu from "../editor/StyleMenu";

import "../index.css";

// THEME
const darkTheme = createTheme({
   palette: {
      mode: "dark",
      navBkgnd: {
         dark: "#000",
      },
      col1: {
         dark: "#222",
      },
      col2: {
         dark: "#333",
      },
      col3: {
         dark: "#444",
      },
      col4: {
         dark: "#555",
      },
      background: {
         default: "#151515",
      },
      boxOverride: {
         border: { dark: "rgba(255,255,255,.25)" },
         background: {
            dark: "rgba(0,0,0,.35)",
         },
      },
   },
});

function Dashboard() {
   const socket = useContext(SocketContext);
   const [DB, setDB] = useState({});
   const [rankingState, setRankingState] = useState([]);
   const [presetColors, setPresetColors] = useState([]);
   const [manualScoring, setManualScoring] = useState();

   const [styles, setStyles] = useState([]);
   const [selectedLayout, setSelectedLayout] = useState();

   const refreshDB = () => {
      socket.emit("refreshDBLeaderboard");
   };

   const handleKeyPress = (e) => {
      if (e.keyCode === 13) {
         e.target.blur();
      }
   };

   // COLOR PICKER
   const updatePresetColors = () => {
      let colors = ["#000", "#fff"];

      setPresetColors(
         colors.length != "undefined" ? colors : ["#ff0000", "#ccc"]
      );
   };

   const setColor = (value, id) => {
      valueChildUpdate("styles", "color", value);
   };

   const setTeamColor = (value, id) => {
      updateObjectValue("teams", "color", value, "text", id);
   };

   const rgba2hex = (color) => {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      const hex = `#${(
         (1 << 24) +
         (parseInt(rgba[0]) << 16) +
         (parseInt(rgba[1]) << 8) +
         parseInt(rgba[2])
      )
         .toString(16)
         .slice(1)}`;

      return hex;
   };

   // EFFECTS

   useEffect(() => {
      socket.emit("getDBLeaderboard", (payload) => {
         setDB(payload);
         setSelectedLayout(payload.activeLayout);
         setManualScoring(payload.manualScoring);
      });

      socket.emit("getStyles", (response) => {
         setStyles(response.stylesLeaderboard);
      });
   }, []);

   useEffect(() => {
      if (selectedLayout) {
         socket.emit("setActiveLayout", selectedLayout);
      }
   }, [selectedLayout]);

   useEffect(() => {
      if (!isNaN(manualScoring)) {
         socket.emit("setManualScoring", manualScoring);
      }
   }, [manualScoring]);

   useEffect(() => {
      if (DB.teams && DB.rounds) {
         Array(DB.rounds)
            .fill()
            .map((value, c) => getResults(DB.teams, c));
      }
   }, [DB.teams]);

   useEffect(() => {
      {
         /* console.log(rankingState); */
      }
   }, [rankingState]);

   useEffect(() => {
      socket.on("refreshData", (arrayKey, payload, lastUpdate) => {
         setDB((prevState) => {
            let updatedState = { ...prevState };
            updatedState[arrayKey] = payload;
            updatedState.lastUpdate = lastUpdate;
            return updatedState;
         });
      });
   }, [socket]);

   const updateValue = (arrayKey, value, valueType) => {
      if (valueType === "int") {
         value = parseInt(value);
      }

      socket.emit("valueUpdate", arrayKey, value);
   };

   const valueChildUpdate = (objKey, childKey, value) => {
      socket.emit("valueChildUpdate", objKey, childKey, value);
   };

   const updateArrayValue = (arrayKey, value, valueType, index) => {
      if (valueType === "int") {
         value = parseInt(value);
      }

      socket.emit("arrayUpdate", arrayKey, value, index);
   };

   const updateObjectValue = (arrayKey, objKey, value, valueType, index) => {
      if (valueType === "int") {
         value = parseInt(value);
      }

      socket.emit("arrayUpdateObject", arrayKey, objKey, value, index);
   };

   const updateObjectArrayValue = (arrayKey, objKey, row, col, value) => {
      socket.emit("arrayUpdateObjectArray", arrayKey, objKey, row, col, value);
   };

   const sumResults = (row) => {
      let sum = 0;
      DB.teams[row].results.map((rank, i) =>
         rank > 0 ? (sum += DB.ranking[rank - 1] * DB.multiplier[i]) : 0
      );
      return sum;
   };

   const sumManualResults = (row) => {
      let sum = 0;
      DB.teams[row].manualResults.map((result, i) =>
         result ? (sum += result * DB.multiplier[i]) : 0
      );
      return sum;
   };

   const getResults = (teams, col) => {
      let resultSet = teams.map((team) => team.results[col]);

      let grouped = _.groupBy(resultSet, function (n) {
         return n;
      });

      let roundArray = Array(DB.teams.length)
         .fill()
         .map((value, i) => {
            return { id: i + 1, current: grouped[i + 1] };
         });

      _.each(roundArray, function (row, i) {
         if (roundArray[i].current && roundArray[i].current.length >= 1) {
            if (roundArray[i].current.length == 1) {
               _.set(roundArray[i], "className", "active");
            } else if (
               roundArray[i].current.length > 1 &&
               !roundArray[i].disabled
            ) {
               _.set(roundArray[i], "className", "tie");
               _.times(roundArray[i].current.length - 1, function (t) {
                  _.set(roundArray[i + t + 1], "className", "disabled");
               });
            }

            if (
               roundArray[i - 1] &&
               roundArray[i - 1].className == "tie" &&
               roundArray[i].current.length > 0
            ) {
               _.set(roundArray[i], "alert", true);
            }
            return row;
         }
      });

      setRankingState((prevState) => {
         let updatedState = [...prevState];
         updatedState[col] = roundArray;
         return updatedState;
      });
   };

   const addTeam = () => {
      socket.emit("arrayInsert", "teams", {
         name: "",
         results: Array(DB.rounds).fill(0),
         manualResults: Array(DB.rounds).fill(0),
      });
   };

   const deleteTeam = (id) => {
      socket.emit("arrayDeleteKey", "teams", id);
   };

   const zeroResults = (col) => {
      socket.emit("zeroResults", col);
   };

   const zeroManualResults = (col) => {
      socket.emit("zeroManualResults", col);
   };

   return (
      <ThemeProvider theme={darkTheme}>
         <CssBaseline />

         <Box sx={{ mx: "auto", py: 2, px: 5 }}>
            {DB && (
               <>
                  {/* {JSON.stringify(DB)} */}
                  <Grid
                     container
                     spacing={3}
                     sx={{ mb: 2 }}
                     justifyContent='center'
                     alignItems='center'
                  >
                     <Grid item xs={6}>
                        <Typography
                           variant='h6'
                           gutterBottom
                           component='div'
                           sx={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                           }}
                        >
                           LEADERBOARD
                        </Typography>
                        <i>
                           Last Updated:{" "}
                           {DB.lastUpdate
                              ? new Date(DB.lastUpdate).toLocaleString()
                              : ""}
                        </i>
                     </Grid>
                     <Grid item xs>
                        <Grid
                           container
                           justifyContent='center'
                           alignItems='center'
                        >
                           <Grid item xs={8}>
                              <StyleMenu
                                 styles={styles}
                                 selectedLayout={selectedLayout}
                                 setSelectedLayout={setSelectedLayout}
                              />
                           </Grid>
                           <Grid item xs={4}>
                              <Button
                                 variant='outlined'
                                 href='/editor/leaderboard'
                                 size='small'
                                 sx={{ ml: 3 }}
                                 fullWidth
                              >
                                 EDIT LAYOUTS
                              </Button>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>

                  <Box sx={{ mt: 5, mb: 5, flexGrow: 1 }}>
                     <Button
                        onClick={() => setManualScoring(!manualScoring)}
                        variant={manualScoring ? "contained" : "outlined"}
                        color='error'
                     >
                        Manual Scoring
                     </Button>

                     {!manualScoring && (
                        <>
                           <Typography
                              variant='h5'
                              gutterBottom
                              component='div'
                              sx={{ mb: 2 }}
                           >
                              Ranking Points
                           </Typography>

                           <Grid container spacing={2}>
                              {DB.ranking &&
                                 Array(DB.teams.length)
                                    .fill()
                                    .map((value, i) => (
                                       <Grid item xs key={"rankingRow" + i}>
                                          <TextField
                                             fullWidth
                                             label={
                                                "Rank " + (i + 1) + " Points"
                                             }
                                             type='text'
                                             placeholder='Ranking'
                                             tabIndex='0'
                                             defaultValue={
                                                DB.ranking[i]
                                                   ? DB.ranking[i]
                                                   : 0
                                             }
                                             onKeyDown={handleKeyPress}
                                             onBlur={(e) =>
                                                updateArrayValue(
                                                   "ranking",
                                                   e.target.value,
                                                   "int",
                                                   i
                                                )
                                             }
                                          />
                                       </Grid>
                                    ))}
                           </Grid>
                        </>
                     )}
                  </Box>
                  <Box sx={{ mt: 5, flexGrow: 1 }}>
                     <Grid container spacing={3} sx={{ pb: 2 }}>
                        <Grid item xs>
                           <Typography
                              variant='h5'
                              gutterBottom
                              component='div'
                           >
                              Leaderboard
                           </Typography>
                        </Grid>
                        <Grid item xs>
                           <Grid
                              container
                              justifyContent='flex-end'
                              alignItems='center'
                              spacing={1}
                           >
                              {DB.rounds && (
                                 <Grid item>
                                    <TextField
                                       label='# of Rounds'
                                       type='number'
                                       placeholder='# of Rounds'
                                       defaultValue={DB.rounds}
                                       size='small'
                                       sx={{ mx: 2, width: 200 }}
                                       inputProps={{ min: 1, max: 6 }}
                                       onFocus={(e) => e.target.blur()}
                                       onChange={(e) =>
                                          updateValue(
                                             "rounds",
                                             e.target.value,
                                             "int"
                                          )
                                       }
                                    />
                                 </Grid>
                              )}

                              <Grid item>
                                 <Button
                                    onClick={addTeam}
                                    variant='outlined'
                                    align='right'
                                 >
                                    + Add Team
                                 </Button>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>

                     <Grid container spacing={3} sx={{ mt: 15 }}>
                        {DB.teams &&
                           rankingState.length > 0 &&
                           DB.teams.map((row, i) => (
                              <React.Fragment key={"teamRow" + row.id}>
                                 <Grid
                                    item
                                    xs={3}
                                    sx={{ borderBottom: "1px solid #ccc" }}
                                 >
                                    <TextField
                                       fullWidth
                                       sx={{ mb: 1 }}
                                       label='Name'
                                       type='text'
                                       placeholder='Name'
                                       tabIndex='1'
                                       defaultValue={row.name}
                                       onKeyDown={handleKeyPress}
                                       onBlur={(e) =>
                                          updateObjectValue(
                                             "teams",
                                             "name",
                                             e.target.value,
                                             "text",
                                             i
                                          )
                                       }
                                       InputProps={{
                                          endAdornment: (
                                             <InputAdornment position='end'>
                                                <IconButton
                                                   edge='end'
                                                   color='primary'
                                                   onClick={() =>
                                                      deleteTeam(row.id)
                                                   }
                                                >
                                                   <DeleteIcon />
                                                </IconButton>
                                             </InputAdornment>
                                          ),
                                       }}
                                    />

                                    {/* <ColorPicker
                                       color={
                                          row.color
                                             ? row.color
                                             : { r: 255, g: 255, b: 255, a: 1 }
                                       }
                                       updateSwatches={updatePresetColors}
                                       callback={setTeamColor}
                                       id={i}
                                       col='teamColor'
                                       presetColors={presetColors}
                                       width={80}
                                       height={30}
                                    /> */}
                                 </Grid>
                                 <Grid
                                    item
                                    xs={9}
                                    sx={{ borderBottom: "1px solid #ccc" }}
                                 >
                                    <Grid container spacing={2} sx={{ pb: 2 }}>
                                       {Array(DB.rounds)
                                          .fill()
                                          .map((value, c) => (
                                             <Grid
                                                item
                                                xs
                                                key={
                                                   "row" +
                                                   i +
                                                   "round" +
                                                   c +
                                                   "rankCol"
                                                }
                                                sx={{ position: "relative" }}
                                             >
                                                {i == 0 && (
                                                   <Box
                                                      sx={{
                                                         top: -120,
                                                         position: "absolute",
                                                      }}
                                                   >
                                                      <TextField
                                                         fullWidth
                                                         size='small'
                                                         label='Round Title'
                                                         type='text'
                                                         tabIndex='1'
                                                         defaultValue={
                                                            DB.roundTitles[c]
                                                               ? DB.roundTitles[
                                                                    c
                                                                 ]
                                                               : ""
                                                         }
                                                         onKeyDown={
                                                            handleKeyPress
                                                         }
                                                         onBlur={(e) =>
                                                            updateArrayValue(
                                                               "roundTitles",
                                                               e.target.value,
                                                               "",
                                                               c
                                                            )
                                                         }
                                                         sx={{ my: 2 }}
                                                      />
                                                      <Button
                                                         onClick={() =>
                                                            manualScoring
                                                               ? zeroManualResults(
                                                                    c
                                                                 )
                                                               : zeroResults(c)
                                                         }
                                                         variant='outlined'
                                                         size='small'
                                                      >
                                                         ZERO PTS
                                                      </Button>
                                                      <TextField
                                                         label='Multiplier'
                                                         type='number'
                                                         defaultValue={
                                                            DB.multiplier[c]
                                                         }
                                                         size='small'
                                                         sx={{
                                                            mx: 2,
                                                            width: 70,
                                                         }}
                                                         inputProps={{ min: 1 }}
                                                         onChange={(e) =>
                                                            updateArrayValue(
                                                               "multiplier",
                                                               e.target.value,
                                                               "int",
                                                               c
                                                            )
                                                         }
                                                      />
                                                   </Box>
                                                )}

                                                {manualScoring && (
                                                   <ManualScoreEntry
                                                      arrayKey='teams'
                                                      objKey='manualResults'
                                                      row={i}
                                                      col={c}
                                                      value={
                                                         DB.teams[i]
                                                            .manualResults[c]
                                                      }
                                                      callback={
                                                         updateObjectArrayValue
                                                      }
                                                   />
                                                )}

                                                {!manualScoring && (
                                                   <RankingComponent
                                                      arrayKey='teams'
                                                      objKey='results'
                                                      row={i}
                                                      col={c}
                                                      curSet={rankingState[c]}
                                                      points={
                                                         DB.ranking[
                                                            DB.teams[i].results[
                                                               c
                                                            ] - 1
                                                         ]
                                                      }
                                                      multiplier={
                                                         DB.multiplier[c]
                                                      }
                                                      value={
                                                         DB.teams[i].results[c]
                                                      }
                                                      options={DB.teams.length}
                                                      callback={
                                                         updateObjectArrayValue
                                                      }
                                                   />
                                                )}
                                             </Grid>
                                          ))}
                                       <Grid item xs>
                                          <h3 style={{ margin: "0 0 1em 0" }}>
                                             Total Points
                                          </h3>
                                          {!manualScoring && sumResults(i)}

                                          {manualScoring && sumManualResults(i)}
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </React.Fragment>
                           ))}
                     </Grid>
                  </Box>
               </>
            )}
         </Box>
         <Button
            sx={{ mt: 3 }}
            variant='outlined'
            color='error'
            onClick={refreshDB}
            sx={{ ml: 3 }}
         >
            Reload DB
         </Button>
      </ThemeProvider>
   );
}

export default Dashboard;
