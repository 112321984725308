import { useState } from "react";
import _ from "lodash";
import { motion, animatePresence, layout } from "framer-motion";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// COMPONENTS
import TextScroller from "../viewer/TextScroller";
import TextSquisher from "../viewer/TextSquisher";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Layout(props) {
   const sumResults = (id) => {
      let sum = 0;

      let index = _.findIndex(
         props.leaderboard,
         (row) => {
            return row.id === id;
         },
         0
      );

      if (props.manualScoring) {
         props.leaderboard[index].manualResults.map((result, i) =>
            result ? (sum += result * props.multiplier[i]) : 0
         );
      } else {
         props.leaderboard[index].results.map((rank, i) =>
            rank > 0
               ? (sum += props.ranking[rank - 1] * props.multiplier[i])
               : 0
         );
      }

      return sum;
   };

   const rgba2hex = (color) => {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      const hex = `#${(
         (1 << 24) +
         (parseInt(rgba[0]) << 16) +
         (parseInt(rgba[1]) << 8) +
         parseInt(rgba[2])
      )
         .toString(16)
         .slice(1)}`;

      return hex;
   };

   return (
      <>
         {props.leaderboard && (
            <>
               <Grid
                  className='viewerTable'
                  container
                  justifyContent='center'
                  alignItems='stretch'
                  key={"activeLeaderboard"}
                  sx={{
                     mb: 1,
                     height: "100%",
                     width: "100%",
                     top: 0,
                     left: 0,
                     zIndex: 100,
                     boxSizing: "border-box",
                     position: "relative",
                  }}
               >
                  <Grid item xs={12}>
                     <Grid
                        container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='stretch'
                        sx={{
                           textAlign:
                              props.styles.container.leaderboard.textAlign,
                           fontFamily: props.styles.container.leaderboard
                              .fontFamilyHeader
                              ? props.styles.container.leaderboard.fontFamilyHeader.split(
                                   "."
                                )[0]
                              : "unset",
                           fontSize:
                              props.styles.container.leaderboard.fontSizeHeader,
                           fontWeight:
                              props.styles.container.leaderboard
                                 .fontWeightHeader,

                           backgroundColor:
                              props.styles.container.leaderboard
                                 .backgroundColor,

                           color: props.styles.container.leaderboard.colorHeader
                              ? props.styles.container.leaderboard.colorHeader
                              : "#ffffff",
                        }}
                     >
                        <Grid item xs sx={{ minWidth: 450 }}></Grid>

                        {props.leaderboard[0].results.map((roundScore, r) => (
                           <Grid
                              item
                              xs
                              key={"leaderboardHeader" + r}
                              sx={{ overflow: "hidden", whiteSpace: "nowrap" }}
                           >
                              {props.roundTitles[r]}
                           </Grid>
                        ))}

                        <Grid item xs>
                           Total
                        </Grid>
                     </Grid>
                  </Grid>
                  {_.sortBy(props.leaderboard, function (array) {
                     let valueArray = [];

                     if (props.manualScoring) {
                        _.each(array.manualResults, (row, n) => {
                           valueArray.push(row ? row * props.multiplier[n] : 0);
                        });
                     } else {
                        _.each(array.results, (row, n) => {
                           valueArray.push(
                              props.ranking[row - 1]
                                 ? props.ranking[row - 1] * props.multiplier[n]
                                 : 0
                           );
                        });
                     }

                     const total = valueArray.reduce(
                        (partialSum, a) => partialSum + a,
                        0
                     );

                     return -total;
                  }).map((row, i) => (
                     <Grid
                        item
                        xs={12}
                        key={"leaderboardRow" + row.id}
                        component={motion.div}
                        layout='position'
                        sx={{
                           textAlign:
                              props.styles.container.leaderboard.textAlign,
                           fontFamily: props.styles.container.leaderboard
                              .fontFamily
                              ? props.styles.container.leaderboard.fontFamily.split(
                                   "."
                                )[0]
                              : "unset",
                           fontSize:
                              props.styles.container.leaderboard.fontSize,
                           fontWeight:
                              props.styles.container.leaderboard.fontWeight,
                           color: props.styles.container.leaderboard.color
                              ? props.styles.container.leaderboard.color
                              : "#ffffff",
                        }}
                     >
                        <Grid
                           container
                           direction='row'
                           justifyContent='flex-start'
                           alignItems='center'
                           key={"activevertical-" + props.id}
                           sx={{
                              color: props.styles.container.leaderboard.color,
                           }}
                        >
                           <Grid
                              item
                              xs
                              sx={{
                                 minWidth: 450,
                                 py: props.styles.container.leaderboard.py,
                              }}
                           >
                              <TextSquisher text={row.name} key={row.name} />
                              {/* <TextScroller
                                       text={row.name}
                                       key={row.name}
                                    /> */}
                           </Grid>

                           {props.manualScoring &&
                              row.manualResults.map((roundScore, r) => (
                                 <Grid
                                    item
                                    xs
                                    key={"roundScoreRow" + i + "round" + r}
                                 >
                                    {roundScore
                                       ? roundScore * props.multiplier[r]
                                       : 0}
                                 </Grid>
                              ))}
                           {!props.manualScoring &&
                              row.results.map((roundScore, r) => (
                                 <Grid
                                    item
                                    xs
                                    key={"roundScoreRow" + i + "round" + r}
                                 >
                                    {roundScore
                                       ? props.ranking[roundScore - 1] *
                                         props.multiplier[r]
                                       : 0}
                                 </Grid>
                              ))}
                           <Grid item xs>
                              {sumResults(row.id)}
                           </Grid>
                        </Grid>
                     </Grid>
                  ))}
               </Grid>

               {/* LEADERBOARD DROP SHADOW */}
               {props.styles.container.dropshadow.enable && (
                  <Grid
                     className='viewerTable'
                     container
                     justifyContent='center'
                     alignItems='stretch'
                     key={"activeLeaderboardShadow"}
                     sx={{
                        mb: 1,
                        height: "100%",
                        width: "100%",
                        zIndex: 80,
                        boxSizing: "border-box",
                        position: "relative",
                        left: props.styles.container.dropshadow.xPos + "px",
                        top:
                           "calc(" +
                           props.styles.container.dropshadow.yPos +
                           "px)",
                        transform: "translateY(-100%)",
                        color: props.styles.container.dropshadow.color,

                        WebkitTextStroke:
                           props.styles.container.dropshadow.borderWidth +
                           "px " +
                           props.styles.container.dropshadow.borderColor,
                     }}
                  >
                     <Grid item xs={12}>
                        <Grid
                           container
                           direction='row'
                           justifyContent='flex-start'
                           alignItems='stretch'
                           sx={{
                              textAlign:
                                 props.styles.container.leaderboard.textAlign,
                              fontFamily: props.styles.container.leaderboard
                                 .fontFamilyHeader
                                 ? props.styles.container.leaderboard.fontFamilyHeader.split(
                                      "."
                                   )[0]
                                 : "unset",
                              fontSize:
                                 props.styles.container.leaderboard
                                    .fontSizeHeader,
                              fontWeight:
                                 props.styles.container.leaderboard
                                    .fontWeightHeader,

                              color: props.styles.container.dropshadow.color
                                 ? props.styles.container.dropshadow.color
                                 : "#000",
                           }}
                        >
                           <Grid item xs sx={{ minWidth: 450 }}></Grid>

                           {props.leaderboard[0].results.map(
                              (roundScore, r) => (
                                 <Grid
                                    item
                                    xs
                                    key={"leaderboardHeader" + r}
                                    sx={{
                                       overflow: "hidden",
                                       whiteSpace: "nowrap",
                                    }}
                                 >
                                    {props.roundTitles[r]}
                                 </Grid>
                              )
                           )}

                           <Grid item xs>
                              Total
                           </Grid>
                        </Grid>
                     </Grid>
                     {_.sortBy(props.leaderboard, function (array) {
                        let valueArray = [];

                        if (props.manualScoring) {
                           _.each(array.manualResults, (row, n) => {
                              valueArray.push(
                                 row ? row * props.multiplier[n] : 0
                              );
                           });
                        } else {
                           _.each(array.results, (row, n) => {
                              valueArray.push(
                                 props.ranking[row - 1]
                                    ? props.ranking[row - 1] *
                                         props.multiplier[n]
                                    : 0
                              );
                           });
                        }

                        const total = valueArray.reduce(
                           (partialSum, a) => partialSum + a,
                           0
                        );

                        return -total;
                     }).map((row, i) => (
                        <Grid
                           item
                           xs={12}
                           key={"leaderboardRow" + row.id}
                           component={motion.div}
                           layout='position'
                           sx={{
                              textAlign:
                                 props.styles.container.leaderboard.textAlign,
                              fontFamily: props.styles.container.leaderboard
                                 .fontFamily
                                 ? props.styles.container.leaderboard.fontFamily.split(
                                      "."
                                   )[0]
                                 : "unset",
                              fontSize:
                                 props.styles.container.leaderboard.fontSize,
                              fontWeight:
                                 props.styles.container.leaderboard.fontWeight,

                              color: props.styles.container.dropshadow.color
                                 ? props.styles.container.dropshadow.color
                                 : "#000",
                           }}
                        >
                           <Grid
                              container
                              direction='row'
                              justifyContent='flex-start'
                              alignItems='center'
                              key={"activevertical-" + props.id}
                              sx={{
                                 color: props.styles.container.dropshadow.color
                                    ? props.styles.container.dropshadow.color
                                    : "#000",
                              }}
                           >
                              <Grid
                                 item
                                 xs
                                 sx={{
                                    minWidth: 450,
                                    py: props.styles.container.leaderboard.py,
                                 }}
                              >
                                 <TextSquisher text={row.name} key={row.name} />
                                 {/* <TextScroller
                                       text={row.name}
                                       key={row.name}
                                    /> */}
                              </Grid>
                              {props.manualScoring &&
                                 row.manualResults.map((roundScore, r) => (
                                    <Grid
                                       item
                                       xs
                                       key={"roundScoreRow" + i + "round" + r}
                                    >
                                       {roundScore
                                          ? roundScore * props.multiplier[r]
                                          : 0}
                                    </Grid>
                                 ))}
                              {!props.manualScoring &&
                                 row.results.map((roundScore, r) => (
                                    <Grid
                                       item
                                       xs
                                       key={"roundScoreRow" + i + "round" + r}
                                    >
                                       {roundScore
                                          ? props.ranking[roundScore - 1] *
                                            props.multiplier[r]
                                          : 0}
                                    </Grid>
                                 ))}
                              <Grid item xs>
                                 {sumResults(row.id)}
                              </Grid>
                           </Grid>
                        </Grid>
                     ))}
                  </Grid>
               )}
            </>
         )}
      </>
   );
}
